.loginForm input {
    margin-top: 1rem;
}

.loginForm {
    /* margin-top: 20vh; */
    margin-bottom: 15vh;
}

input[type="submit"] {
    margin-top: 2rem;
}

.login-page {
    min-height: 60vh;
}

.sectionHead {
    margin-top: 10vh;
}

.loading {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    margin-top: 25vh;
    z-index: 10;
}
