.slick-slider {
  overflow: hidden!important;
}

.heroBanner {
  height: 25rem;
  background-position: center;
}

.firstBanner {
  background-image: url('/home/banner-test.jpg');
}

.infoBox {
  min-height:20rem;
}

.boxLight {
  background:var(--light-blue);
  color:var(--primary-blue);
}

.boxDark {
  background:var(--primary-blue);
  color:white;
}

.infoBox h2::before {
  content: '';
  background-size: 3.5rem 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  display: block;
  position: absolute;
  margin: -1rem 0 0 1rem;
}

.infoBox p {
  padding:.5rem 1rem 1rem 1rem;
}

.titleBox {
  text-align: center;
  padding:3rem .5rem 1.5rem .5rem;
}

.whoWeAre::before {
  background: url(/home/who-we-are.png);
}

.whatWeDo::before {
  background: url(/home/what-we-do.png);
}

.whereWeDo::before {
  background: url(/home/where-we-do-it.png);
  margin: -1rem 0 0 .25rem!important;
}

.blogBox h3 {
  color:var(--primary-blue);
  padding-bottom:1rem;
  transition:.2s;
}

.blogBox img {
  width:100%;
}

.blogBox:hover h3 {
  color:var(--light-blue);
}

.slick-dots {
  bottom:20px!important;
}

.slick-dots li button:before {
  font-size:1rem!important;
}

.compareImg img {
  width: 552px;
}

.compareImg {
  display:inline-block;
}

.compareImgWrap {
  overflow:hidden;
}

.compareImg:last-child {
  border-left: 3px white solid;
}

.compareImg:first-child {
  border-right: 3px white solid;
}

.beforeCompare,
.afterCompare {
  position:absolute;
  z-index:10;
  margin-top:-3rem;
  width:100%;
  height:3rem;
  padding-top:.5rem;
  font-size:1.25rem;
  font-weight:600;
}

.beforeCompare {
  background:var(--light-blue);
  color:var(--primary-blue);
  padding-left:13rem;
}

.afterCompare {
  background:var(--primary-blue);
  color:var(--light-blue);
  padding-left:15rem;
}

.faIconCompare {
  text-align: right;
  width: 4.25rem!important;
  height: 4.25rem;
  position: absolute;
  color: var(--light-blue);
  z-index: 100;
  margin-left: -16.5rem;
  margin-top: -1.15rem;
}

.compareSection {
  display:block;
}

.covidBox {
  margin:2rem 0rem;
  padding:.75rem;
  border: 2px solid var(--warning);
  border-radius:10px;
  text-align:center;
}

a.covidNotice {
  font-size:1.25rem;
  text-transform:uppercase;
  font-weight:500;
  margin-bottom: 0;
  color:var(--warning);
}

a.covidNotice:hover {
  color:var(--warning);
  text-decoration: underline;
  cursor: pointer;
}

.covidNotice span {
  font-size:1rem;
  font-weight:400;
}

.faNotice {
  margin-right:.5rem;
}

.ctaButtonWrapper {
  text-align: center;
  margin-top: 15%;
}

.getQuoteWrap {
  background: #ffffffbd;
  padding: 2rem;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
}

.getQuoteWrap h2 {
  color:var(--primary-blue);
  padding-bottom:1rem;
  margin-top: -.4rem;
}

@media (max-width:576px) {
  .blogBox {
    margin:1rem 0;
  }

  .compareSection {
    display:none;
  }

  .getQuoteWrap {
    width: 95%;
  }

  .ctaButtonWrapper {
    text-align: center;
    margin-top: 25%;
  }

  .getQuoteWrap button.btn {
    font-size: 1.25rem;
    width:17rem;
    margin-top:1rem;
  }
}