.quoteTab {
    padding:.75rem 2rem;
    background:grey;
    font-size:1.5rem;
    font-weight:500;
}

.quoteTab:first-child {
    border-bottom-left-radius:10px;
}

.quoteTab:last-child {
    border-bottom-right-radius:10px;
}

.quoteTabWrap {
    margin-top:.55rem;
    margin-bottom:1rem;  
}

@media (max-width:576px) {

    .quoteTab {
        font-size:.8rem;
        padding:.6rem;
        display:block;
        border-radius:0!important;
    }

  }