.quotePageWrapper select {
    font-size:1.25rem;
    padding:.5rem 1rem;
    margin-bottom:1rem;
    border-radius:5px;
    min-width:15rem;
}

.quoteTotal {
    background: var(--light-blue);
    color: var(--primary-blue);
    padding: 1rem 1.5rem;
    font-size: 2rem;
    font-weight: 500;
    width: 17rem;
    border-radius: 10px;
    margin-bottom:2rem;
}

.questionMarkW {
    display: inline-block;
    padding: 0 .5rem;
}

.questionMark {
    width: 1rem;
}

.infoBoxQuote {
    display:inline;
    position: absolute;
    background:white;
    color:var(--primary-blue);
    max-width: 25rem;
    padding: 1rem;
    /* transition:.3s; */
    border: 2px solid var(--primary-blue);
    border-radius:10px;
    font-size:.9rem;
    font-weight:500;
    pointer-events: none;
    margin-left: 1rem;
}

.oldQuote {
    color:red;
    text-decoration: line-through;
    margin-left:.5rem;
    font-size:1.5rem;
}

label.contactMe {
    display:inline-block!important;
}

input[type=checkbox] {
    margin-top:2rem;
}

/* FORM (Perhaps move to index?) */

.quoteForm button {
    margin: 3rem 0;
}

.inputbox-after {
    color: var(--primary-blue);
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 300;
}

