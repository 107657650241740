.testimonialWrapper {
    padding: 1rem;
    background:#e8f8ff;;
    border-radius:10px;
    margin: 2rem 1rem;
}

.testimonialWrapper p {
    color:var(--primary-blue)
}

.quoteIcon {
    width: 2rem!important;
    height: 2rem;
    display:inline-block;
    color:var(--primary-blue);
}

p.testimonialAuthor {
    font-size:.8rem;
    font-weight:500;
}

.testimonialsPageWrapper h5 {
    margin:1rem;
    color:var(--primary-blue);
}