.rich-text-wrapper {
    border: 1px solid var(--primary-blue);
    min-height: 15rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.rich-text-editor {
    padding: 0.5rem;
    font-size: 0.9rem;
    color: black;
}

.editor-toolbar,
.rdw-list-wrapper {
    display: inline-block;
}

.rdw-editor-toolbar {
    background: #fbfbfb;
    padding: 0.25rem 0.5rem;
}

.editor-toolbar div,
.rdw-list-wrapper div {
    display: inline-block;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    background: #f8f8f8;
    margin: 0.25rem;
    border: 1px solid #eeeeee;
    color: black;
}

.editor-toolbar img,
.rdw-list-wrapper img {
    width: 90%;
}

.editor-toolbar.font-style div {
    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.3rem 0.5rem;
}

.DraftEditor-editorContainer {
    max-height: 50vh;
    overflow: scroll;
}

.ReactModal__Overlay {
    background-color: #000000bf !important;
}
